import PageWrapper from "components/UI/PageWrapper";
import { AppContext } from "hooks/use-app-data";
import React, { MouseEventHandler, useContext, useEffect, useRef, useState } from "react";
import formatNumber from "utils/format-number";
import tg from 'utils/tg'
import HighlightStep from "./Introduction";
import { useNavigate } from "react-router-dom";
import sendTaps from "api/send-taps";
import { motion } from "framer-motion";
interface TapAnimation {
  id: number;
  x: number;
  y: number;
}
const tgUserData = tg.initDataUnsafe
const Home = () => {
  const {user,setUser} = useContext(AppContext)
  const navigate = useNavigate()
  const [AnimationEnd, setAnimationEnd] = useState<boolean>(false)
  const [showIntro, setshowIntro] = useState<boolean | null>(null)
  const [tapCount, setTapCount] = useState(0);
  // const [currentBalance, setcurrentBalance] = useState<number | null>(user?.balance || null);
  // const [currentEnergy, setcurrentEnergy] = useState<number | null>(user?.energy || null);
  const [tapAnimations, setTapAnimations] = useState<TapAnimation[]>([]);
  const [nextId, setNextId] = useState(1);
  const timer = useRef<NodeJS.Timeout | null>(null)
  const currentBalance = user?.balance
  console.log("🚀 ~ Home ~ currentBalance:", currentBalance)
  const currentEnergy = user?.energy || 0
  const setcurrentBalance = (b:number) => {
    if (user) setUser({...user,balance:b})
  } 
  const setcurrentEnergy = (e:number) => {
    if (user) setUser({...user,energy:e})
  } 

  const handleTap = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!currentEnergy || !currentBalance) {
      tg.HapticFeedback?.notificationOccurred('error')
      return
    }
    if (currentBalance === null) return
    setTapCount(tapCount+1)
    const {balance,energy} = user
    setUser({...user,balance:balance-1,energy:energy-1})
    tg.HapticFeedback?.impactOccurred('soft')

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const newAnimation = {
      id: nextId,
      x: x,
      y: y,
    };

    setTapAnimations([...tapAnimations, newAnimation]);
    setNextId(nextId + 1);

    setTimeout(() => {
      setTapAnimations((prev) => prev.filter((anim) => anim.id !== newAnimation.id));
    }, 1000);
  }

  useEffect(() => {
    tg.CloudStorage.getItem('intro',(error: string | null, value: null | string) => {
      setshowIntro(value === '')
    })
  }, [])

  useEffect(() => {
    if (!tapCount || !currentEnergy) return
    if(timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(async () => {
      const result = await sendTaps(tapCount,currentEnergy)
      console.log("🚀 ~ timer.current=setTimeout ~ result:", result)
      if (result && user) {
        const {data:{balance,energy}} = result
        setUser({...user,balance,energy})
        setTapCount(0)
      }
    }, 1000);

  }, [tapCount]);
  useEffect(() => {
    if (currentBalance === null && typeof user?.balance === 'number') {
      setcurrentBalance(user?.balance)
    }
    if (currentEnergy === null && typeof user?.energy === 'number') {
      setcurrentEnergy(user?.energy)
    }
  }, [user])
  useEffect(() => {
    tg.BackButton.hide()
    return () => tg.BackButton.show()
  }, [])
  if(!user) return null
  return (
    <PageWrapper onAnimationEnd={() => setAnimationEnd(true)}>
      <div
        className="screen screen-game languageEn"
        style={{
          background: "linear-gradient(180deg, #B23467 0%, #17182C 100%)",
        }}
      >
        <div className="game-header">
          <div className="icon-gradient">
            <div className="game-header-icon">
              <img
                className="game-header-icon-img"
                src={tgUserData.user?.photo_url}
              />
            </div>
          </div>

          <div className="game-header-hello">
            Welcome back, <span className="game-header-hello-name">{tgUserData.user?.first_name}</span>!
          </div>
          <div className="btn-earn-gradient header-btn-top" id="earn" onClick={() => navigate('/tasks')}>
            <div className="btn-earn ">EARN</div>
            <div className="instruction-bloc-desc">
              <span lang="en">Earn money.</span>
            </div>
          </div>
        </div>
        <div className="game-btn-top-container">
          <div className="game-btn-top btn-top-money top" id="lose-per-tap">
            <div className="game-btn-top-title">
              <span>100$</span>
            </div>
            <div className="game-btn-top-desc">
              <span>loose per tap</span>
            </div>
          </div>
          <div className="instruction-bloc-desc">
            <span lang="en">
              Each time you tap on the screen you will lose X coins.
            </span>
          </div>
          <div className="game-btn-top btn-top-check top" id="friends" onClick={() => navigate('/friends')}>
            <div className="game-btn-top-title">
              <span>Check</span>
            </div>
            <div className="game-btn-top-desc">
              <span>your friends</span>
            </div>
          </div>
          <div className="instruction-bloc-desc">
            <span lang="en">Invite your friends and get bonuses.</span>
          </div>
          <div className="game-btn-top btn-top-boost top" id="boost" onClick={() => navigate('/friends')}>
            <div className="game-btn-top-title">
              <span>Boost</span>
            </div>
            <div className="game-btn-top-desc">
              <span>your loose</span>
            </div>
          </div>
          <div className="instruction-bloc-desc">
            <span lang="en">
              The ability to update the energy strip three times a day and the
              ability to turn on the boost mode.
            </span>
          </div>
        </div>
        <div className="user-info header-btn-top" id="leaderboard">
          <div className="instruction-bloc-desc">
            <span lang="en">Click here to see the leaderboard.</span>
          </div>
          <div className="user-info-progress-bar">
            <div
              style={{ width: `${currentEnergy * 100 / user?.max_energy }%` }}
              className="user-info-progress-line"
            ></div>
          </div>
          <div className="user-info-footer">
            <div className="user-info-footer-energy">
              <span>Energy:</span>
              <span className="user-energy" id="user-energy">
                {currentEnergy}k
              </span>
            </div>
            <div className="user-info-footer-level">
              <span className="user-level" id="user-level">
                1
              </span>{" "}
              <span>LVL | </span>
              <span className="user-level-desc">balance to next level</span>
            </div>
          </div>
        </div>
        <motion.div
        whileTap={{scale:0.95}}
        transition={{duration:0.1}}
        onClick={handleTap}
          style={{ backgroundImage: `url(./img/participant-${user?.pers}.png)`,cursor:'pointer' }}
          className="user-tap js-tap user-background-img"
        >
          {tapAnimations.map((anim) => (
        <motion.div
          key={anim.id}
          transition={{duration:0.3}}
          animate={{translateY:-100,opacity:0}}
          style={{
            position:'absolute',
            left: anim.x,
            top: anim.y,
            fontSize:35
          }}
        >
          -1
        </motion.div>
      ))}
        </motion.div>
        <div className="user-info-footer-btn-container">
          <div className="user-info-footer-btn btn-total-coin">
            <span></span>
            <span id="btn-total-coin">{`$ ${formatNumber(currentBalance || 0)}`}</span>
          </div>
          <div className="user-info-footer-btn btn-footer-sell" onClick={() => navigate('/property')}>
            <div className="instruction-bloc-desc">
              <span lang="en">
                Sell your property. Get money and invest in crypto.
              </span>
            </div>
            <span>SELL PROPERTY</span>
          </div>
          <div className="user-info-footer-btn btn-footer-buy " onClick={() => navigate('/buy')}>
            <div className="instruction-bloc-desc">
              <span lang="en">Buy crypto and become digital millionaire.</span>
            </div>
            <span>BUY CRYPTO</span>
          </div>
        </div>
      </div>
      {(showIntro && AnimationEnd) && <HighlightStep />}
    </PageWrapper>
  );
};

export default Home;
