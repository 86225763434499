import buyGoods from "api/buy-goods";
import getGoods from "api/get-goods";
import PageWrapper from "components/UI/PageWrapper";
import { AppContext } from "hooks/use-app-data";
import React, { useContext, useEffect, useState } from "react";
import Good from "types/good";
import formatNumber from "utils/format-number";
import tg from "utils/tg";

type Props = {};

const Buy = (props: Props) => {
  const [goods, setgoods] = useState<Good[] | null>(null)
  const {user,updateUserInfo} = useContext(AppContext)
  
  const handleBuyGood = async (id:number,price:number) => {
    if ((user?.money_from_sale || 0) < price) {
      tg.showPopup({message:'You don\'t have enough money to buy'})
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    const result = await buyGoods(id)
    if (result && result.result) {
      tg.HapticFeedback.notificationOccurred('success')
      await updateUserInfo()
      await updateGoods()
    }
    else if (result?.error) {
      tg.showPopup({message:result?.error})
      tg.HapticFeedback.notificationOccurred('error')
    }
  }

  const updateGoods = async () => {
    const result = await getGoods()
    if (result) setgoods(result)
  }

  useEffect(() => {
    updateGoods()
  }, [])

  return (
    <PageWrapper>
      <div className="screen screen-buy">
        <div className="screen-title">buy crypto</div>
        <div className="screen-desc">
          Sell your property. Get money and invest <br />
          in crypto to be left with nothing again.
        </div>
        <div className="screen-buy-user-info">
          <div className="buy-user-info-item">
            <span>Your total loose:</span>
            <div className="user-info-total-loose">
              <span id="total-loose">$ {formatNumber(user?.balance || 0)}</span>
            </div>
          </div>
          <div className="buy-user-info-img">
            <img src={`./images/avatar-user/${user?.pers}.png`} alt="" />
          </div>
          <div className="buy-user-info-item">
            <span>Money from sale:</span>
            <div className="user-info-total-loose">
              <span id="money-sale">$ {formatNumber(user?.money_from_sale || 0)}</span>
            </div>
          </div>
        </div>
        <div className="buy-items">
          {goods && goods.map(({id,title,description,price,image,level}) => (
            <div className="buy-item" key={id} onClick={() => handleBuyGood(id,price)}>
            <div className="buy-item-title">
              {title}
            </div>
            <div className="buy-item-img">
              <img src={image} alt="" />
            </div>
            <div className="buy-item-desc">{description}</div>
            <div className="buy-item-footer">
              <div className="buy-item-lvl-wrapper">
                <span>LVL</span>
                <span className="buy-item-lvl">{level}</span>
              </div>
              <div className="buy-item-price-wrapper">
                <span className="buy-item-price">$ {price / 1000} K</span>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Buy;
