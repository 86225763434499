import React, { useContext, useState } from "react";
import { AppContext } from "hooks/use-app-data";
import { useNavigate } from "react-router-dom";
import PageWrapper from "components/UI/PageWrapper";

type Props = {};

const SelectLang = (props: Props) => {
  const { setLanguage, t } = useContext(AppContext);
  const [open, setopen] = useState(false);
  const navigator = useNavigate();

  return (
    <PageWrapper>
      <div className="languageEn screen screen-welcome">
        <span lang="en" className="screen-welcome-title">
          {t("welcome")}
        </span>
        <div className="select-lang-title" lang="en">
          {t("selectLang")}
        </div>

        <div
          className={`select-lang ${open && "select-lang-open"}`}
          onClick={() => setopen(!open)}
        >
          <span className="option option-select">{t("langName")}</span>
          <div className="select-lang-options">
            <div className="option" onClick={() => setLanguage("en")}>
              English
            </div>
            <div className="option" onClick={() => setLanguage("ru")}>
              Русский
            </div>
          </div>
        </div>
        <div
          className="button-action"
          onClick={() => navigator("/select-person")}
        >
          <span lang="en">{t("continue")}</span>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SelectLang;
