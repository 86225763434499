import tg from "utils/tg";
import TasksInfo from "types/tasks-info";
const tg_id = tg.initDataUnsafe.user?.id;

interface ErrorResponce{
    error: string;
}
interface Responce{
  result: boolean;
  data: Data;
}

interface Data {
  success: number;
  balance: number;
}

export default async (id:string):Promise<Responce | ErrorResponce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "task_done";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("task_id", String(id));
    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
