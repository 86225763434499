import { RouteObject } from "react-router-dom";
import SelectLang from "./components/pages/SelectLang/SelectLang";
import Entry from "./components/pages/Entry/Entry";
import SelectPerson from "./components/pages/SelectPerson/SelectPerson";
import Home from "./components/pages/Home/Home";
import Friends from "components/pages/Friends/Friends";
import Boost from "components/pages/Boost/Boost";
import Tasks from "components/pages/Tasks/Tasks";
import Property from "components/pages/Property/Property";
import Buy from "components/pages/Buy/Buy";

const routes:RouteObject[] = [
    {
      path: "/",
      element: <Entry />,
    },
   {
      path: "/select-lang",
      element: <SelectLang />,
    },
    {
      path: "/select-person",
      element: <SelectPerson />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/friends",
      element: <Friends />,
    },
    {
      path: "/boost",
      element: <Boost />,
    },
    {
      path: "/tasks",
      element: <Tasks />,
    },
    {
      path: "/property",
      element: <Property />,
    },
    {
      path: "/buy",
      element: <Buy />,
    },
  ]
  export default routes