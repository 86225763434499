import tg from "utils/tg";

const tg_id = tg.initDataUnsafe.user?.id;

interface Responce {
  result: boolean;
  data: {
    answer:'ok'
  };
}
export default async (lang:string,pers:number):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "settings";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("lang", String(lang));
    API_URL.searchParams.set("pers", String(pers));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
