import PageWrapper from "components/UI/PageWrapper";
import { AppContext } from "hooks/use-app-data";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const Entry = (props: Props) => {
  const [loading, setloading] = useState(30);
  const { user } = useContext(AppContext);
  console.log("🚀 ~ Entry ~ user:", user)
  const navigator = useNavigate();

  useEffect(() => {
    if (loading === 95) {
      if (!user?.lang || !Number(user?.pers)) navigator("/select-lang",{replace:true});
      else navigator("/home",{replace:true});
    }
    setTimeout(() => setloading(loading + 5), 100);
  }, [loading]);

  return (
    <PageWrapper>
      <div className="screen preloader">
        <div className="preloader-progress-wrapper">
          <div className="preloader-progress-count">
            <span id="preloader-progress-count">{loading}</span>%
          </div>
          <div className="prelodaer-progress-bar">
            <div
              style={{ width: `${loading}%` }}
              className="prelodaer-progress-bar-line"
            ></div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Entry;
