import "./style.css";
import { RouterProvider, useLocation, useNavigate, useRoutes } from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";

function App() {
  const navigation = useNavigate()
  const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes);
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false
    tg.BackButton.onClick(() => {
      navigation(-1)
    })
    tg.BackButton.show()
  }

  useEffect(() => {
    tg.expand()
    reloadBackButton()
    tg.onEvent('viewportChanged',reloadBackButton)
  }, [])
  if (!element) return null;

  return (
    <AppContext.Provider value={AppContextValue}>
       <AnimatePresence mode="sync">
       {React.cloneElement(element, { key: location.pathname })}
       </AnimatePresence>
    </AppContext.Provider>
  );
}

export default App;
