import { useState, useEffect } from "react";
import "./HighlightStep.css";
import tg from "utils/tg";
interface Step {
  elementId?: string;
  description: string;
}

const steps: Step[] = [
  {
    elementId: "sell-property",
    description:
      "Продавайте свою собственность. Зарабатывайте деньги и инвестируйте в криптовалюту.",
  },
  {
    elementId: "buy-crypto",
    description: "Покупайте криптовалюту и станьте цифровым миллионером.",
  },
  {
    elementId: "lose-per-tap",
    description: "Каждый раз, когда вы нажимаете на экран, вы теряете X монет.",
  },
  {
    elementId: "friends",
    description: "Приглашайте своих друзей и получайте бонусы.",
  },
  {
    elementId: "boost",
    description:
      "Возможность обновлять запас энергии три раза в день и возможность включать режим Boost.",
  },
  { elementId: "earn", description: "Зарабатывайте" },
  {
    elementId: "leaderboard",
    description: "Нажмите здесь, чтобы ознакомиться с таблицей лидеров.",
  },
  {
    description:
      "На прошлой вы побывали на встрече, где были самые крупные и серьезные представители вашего бизнеса. На данном мероприятии обсуждались глобальные вопросы мира.",
  },
  {
    description:
      "Там, вы познакомились с одним бизнесменом, с которым давно хотели сотрудничать, как вдруг он предлагает вам долю в своей корпорации, на что вы сразу согласились.",
  },
  {
    description:
      "Правда есть один момент, вы представитель бизнеса старой школы и всю свою жизнь пользуетесь наличкой или банковскими услугами, а ваш почти уже партнер предлагает вам внести сумму для покупки доли в крипте, на что вы соглашаетесь.",
  },
  {
    description:
      "Вы судорожно просите своего личного менеджера закупить на всю сумму USDT. После подписания договоров вы отправляете ему долю и с отличным настроением возвращаетесь домой.",
  },
  {
    description:
      "На следующий день вы звоните партнеру, чтобы он показал вам всю внутреннюю кухню бизнеса, но почему то он не берет трубку. Вы проверяете транзакцию и какая досада! Вы перепутали, где поставить запятую и отправили ему все свои деньги, которые ранее лежали на ваших счетах.",
  },
  {
    description:
      "Ну что, броук давай разбирайся в крипте, чтобы тебя опять не кинули.",
  },
];

const HighlightStep: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [descriptionPosition, setDescriptionPosition] =
    useState<React.CSSProperties>({});
  const [withoutElem, setwithoutElem] = useState(false);
  const [showIntro, setshowIntro] = useState(true);
  useEffect(() => {
    const activeStep = steps[currentStep];
    if (!activeStep.elementId) {
      setwithoutElem(true);
      return;
    }
    const element = document.getElementById(activeStep.elementId);

    if (element) {
      element.scrollIntoView({ block: "center" });
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top > windowHeight / 2) {
        // Если элемент ближе к нижней части экрана, описание сверху
        setDescriptionPosition({
          bottom: windowHeight - rect.top + 20,
          // left: rect.left + window.scrollX,
          left: 20,
        });
      } else {
        // Если элемент ближе к верхней части экрана, описание снизу
        setDescriptionPosition({
          top: rect.bottom + window.scrollY + 20,
          // left: rect.left + window.scrollX,
          left: 20,
        });
      }

      element.style.zIndex = "10001";
      element.style.position = "relative"; 
    }

    return () => {
      if (element) {
        element.style.zIndex = "";
        element.style.position = "";
      }
    };
  }, [currentStep]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else setshowIntro(false)
  };

  
  if (!showIntro) {
    tg.CloudStorage.setItem('intro',String(false))
    return null
  }
  return (
    <div className="overlay screen screen-banner">
      {withoutElem ? (
        <div className="banner-info">
          <span>{steps[currentStep].description}</span>
        </div>
      ) : (
        <div className="description-box" style={descriptionPosition}>
          <p>{steps[currentStep].description}</p>
          <div className="controls">
            <div
              id="btn-next-info"
              className="button-action"
              onClick={handleNext}
            >
              <span>Далее</span>
            </div>
          </div>
        </div>
      )}
      {withoutElem && <div id="btn-next-banner" className="button-action" onClick={handleNext}>
            <span>Далее</span>
        </div>}
    </div>
  );
};

export default HighlightStep;
