import { createContext, useEffect, useState } from "react"
import translations from "../i18n"
import Language from "types/language";
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
export const AppContext = createContext<AppContextType>({} as AppContextType)

export default () => {
    const [language, setLanguage] = useState<Language>('en');
    const [user, setUser] = useState<UserData | null>(null)
    const t = (key: keyof typeof translations['en']): string => translations[language][key];
   
    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result?.result) setUser(result.data)
    }

    const AppContextValue:AppContextType = {
        lang:language,
        setLanguage,
        t,
        user,
        updateUserInfo,
        setUser
    }

    useEffect(() => {
        updateUserInfo()
    }, [])
    useEffect(() => {
        if (!user) return;

        const { lang, max_energy, energy_speed } = user;
    
        // Обновляем язык, если он изменился
        if (lang !== language) setLanguage(lang || 'en');
    
        const interval = setInterval(() => {
            setUser((prevUser) => {
                if (!prevUser) return prevUser;
    
                const { energy, max_energy } = prevUser;
    
                // Если энергия меньше максимальной, прибавляем
                if (energy < max_energy) {
                    let nextValue = energy + energy_speed;
                    nextValue = nextValue > max_energy ? max_energy : nextValue;
                    return { ...prevUser, energy: nextValue };
                }
    
                return prevUser;
            });
        }, 1000);
    
        // Очищаем интервал при размонтировании или изменении user
        return () => clearInterval(interval);
    }, [user])

    return {AppContext,AppContextValue}
}