import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "hooks/use-app-data";
import Basim from "./participants-img/basim.png";
import Alex from "./participants-img/alex.png";
import Tommy from "./participants-img/tommy.png";
import formatNumber from "utils/format-number";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-router-dom";
import PageWrapper from "components/UI/PageWrapper";
import setUserData from "api/set-user-data";
type Participant = {
  name: string;
  country: string;
  turnoverPerYear: number;
  business: string;
  image: string;
};

const SelectPerson = () => {
  const { t, lang, updateUserInfo, user } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [checkState, setcheckState] = useState(false);
  const navigator = useNavigate();

  const selectParticipant = async (person: number) => {
    if (loading) return;
    setloading(true);

    const result = await setUserData(lang, person);
    if (result?.result) await updateUserInfo();
    setcheckState(true)
  };

  const participants: Participant[] = [
    {
      name: t("participantBasim"),
      country: t("countyNameUAE"),
      turnoverPerYear: 340982627,
      business: t("businessTypeOilСompany"),
      image: Basim,
    },
    {
      name: t("participantAlexander"),
      country: t("countyNameRussia"),
      turnoverPerYear: 184982627,
      business: t("businessTypeConstructionCompany"),
      image: Alex,
    },
    {
      name: t("participantTommy"),
      country: t("countyNameUSA"),
      turnoverPerYear: 220982627,
      business: t("businessTypeSocialNetwork"),
      image: Tommy,
    },
  ];

  useEffect(() => {
    if(!checkState) return
    if (!user?.lang || !Number(user?.pers)) navigator("/select-lang");
    else navigator("/home");
  }, [user])

  return (
    <PageWrapper>
      <div className="screen screen-select-person languageEn">
        <span className="screen-welcome-title">{t("welcome")}</span>
        <div className="select-lang-title" style={{ marginBottom: 10 }}>
          {t("participantsPresent")}
        </div>

        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={145}
          totalSlides={participants.length}
          isIntrinsicHeight={true}
        >
          <Slider>
            {participants.map(
              ({ name, business, country, image, turnoverPerYear }, i) => (
                <Slide index={i} key={i}>
                  <div className="person">
                    <div className="person-title">
                      <span>{name}</span>
                    </div>
                    <div className="person-img">
                      <img src={image} alt="basim" />
                    </div>
                    <div className="person-desc-container">
                      <div className="person-desc person-desc-country">
                        <div>
                          <div className="desc-title">{t("country")}</div>
                          <div className="desc">{country}</div>
                        </div>
                      </div>
                      <div className="person-desc person-desc-business">
                        <span>
                          <div className="desc-title">{t("business")}</div>
                          <div className="desc">{business}</div>
                        </span>
                      </div>
                      <div className="person-desc person-desc-money">
                        <div>
                          <div className="desc-title">
                            {t("turnoverPerYear")}
                          </div>
                          <div className="desc">
                            ${formatNumber(turnoverPerYear)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="button-action"
                      onClick={() => selectParticipant(i + 1)}
                    >
                      {loading ? (
                        <img
                          style={{ width: 25 }}
                          src="./img/loader.svg"
                          alt=""
                        />
                      ) : (
                        <span>{t("select&continue")}</span>
                      )}
                    </div>
                  </div>
                </Slide>
              )
            )}
          </Slider>
        </CarouselProvider>
      </div>
    </PageWrapper>
  );
};

export default SelectPerson;
