import tg from "utils/tg";
import FriendsInfo from "types/friends-info";
import Good from "types/good";
const tg_id = tg.initDataUnsafe.user?.id;

export default async ():Promise<Good[] | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "goods";
    API_URL.searchParams.set("tg_id", String(tg_id));
    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
