import PageWrapper from 'components/UI/PageWrapper'
import React from 'react'

type Props = {}

const Boost = (props: Props) => {
  return (
    <PageWrapper>
        <div className="screen screen-boosts">
        <div className="screen-title">Boosts</div>
        <div className="screen-desc">
            The ability to update the energy strip three times a day and the ability to turn
            on the boost mode three times a day, which for 15 seconds will give X5 to the existing indicator.
        </div>

        <div className="screen-boost-user-info-items">
            <div className="user-info-item">
                <div>Your balance:</div>
                <div className="user-info-item-balance"><span id="user-balance">8.982.627</span>$</div>
            </div>
            <div className="user-info-item">
                <div>Your energy:</div>
                <div className="user-info-item-energy"><span id="user-energy">1.44</span>k</div>
            </div>
        </div>

        <div className="boosts-items">
            <div className="boosts-item">
                <div className="boosts-item-wrapper">
                    <div className="boost-item-img">
                        <img src="./images/icons/boost.png" alt=""/>
                    </div>
                    <div className="boosts-item-desc">
                        <div className="boosts-item-title">Boost x5</div>
                        <div className="boosts-item-count">
                            <span id="boosts-count">2</span>/<span id="boosts-count-total">3</span>
                        </div>
                    </div>
                </div>
                <div className="boosts-item-icon"></div>
                <div className="boosts-item-time">00:15s</div>
            </div>
            <div className="boosts-item check">
                <div className="boosts-item-wrapper">
                    <div className="boost-item-img">
                        <img src="./images/icons/boost.png" alt=""/>
                    </div>
                    <div className="boosts-item-desc">
                        <div className="boosts-item-title">Boost x5</div>
                        <div className="boosts-item-count">
                            <span id="boosts-count">3</span>/<span id="boosts-count-total">3</span>
                        </div>
                    </div>
                </div>
                <div className="boosts-item-icon"></div>
                <div className="boosts-item-time">00:15s</div>
            </div>
            <div className="boosts-item reload">
                <div className="boosts-item-wrapper">
                    <div className="boost-item-img">
                        <img src="./images/icons/boost.png" alt=""/>
                    </div>
                    <div className="boosts-item-desc">
                        <div className="boosts-item-title">Boost x5</div>
                        <div className="boosts-item-count">
                            <span id="boosts-count">3</span>/<span id="boosts-count-total">3</span>
                        </div>
                    </div>
                </div>
                <div className="boosts-item-icon"></div>
                <div className="boosts-item-time">00:15s</div>
            </div>
            <div className="boosts-item">
                <div className="boosts-item-wrapper">
                    <div className="boost-item-img">
                        <img src="./images/torch.png" alt=""/>
                    </div>
                    <div className="boosts-item-desc">
                        <div className="boosts-item-title">Increase the energy</div>
                        <div className="boosts-item-count">
                            <span id="boosts-count">1</span>/<span id="boosts-count-total">3</span>
                        </div>
                    </div>
                </div>
                <div className="boosts-item-icon"></div>
                <div className="boosts-item-time">00:15s</div>
            </div>
            <div className="boosts-item">
                <div className="boosts-item-wrapper">
                    <div className="boost-item-img">
                        <img src="./images/icons/boost.png" alt=""/>
                    </div>
                    <div className="boosts-item-desc">
                        <div className="boosts-item-title">Loose per tap +1</div>
                        <div className="boosts-item-count">
                            <span id="boosts-count"></span><span id="boosts-count-total"></span>
                        </div>
                    </div>
                </div>
                <div className="boosts-item-icon"></div>
                <div className="boosts-item-time">00:15s</div>
            </div>
        </div>
    </div>
    </PageWrapper>
  )
}

export default Boost