import getProperties from 'api/get-properties'
import sellProperty from 'api/sell-property'
import PageWrapper from 'components/UI/PageWrapper'
import { AppContext } from 'hooks/use-app-data'
import React, { useContext, useEffect, useState } from 'react'
import PropertyType from 'types/property'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'

type Props = {}

const Property = (props: Props) => {
    const [propterties, setpropterties] = useState<PropertyType[] | null>(null)
    const {user,updateUserInfo} = useContext(AppContext)

    const sellPropertyHandle = async (id:string | number) => {
        const result = await sellProperty(id)
        if (result && result.result) {
            await updateUserInfo()
            await updateProperties()
            tg.HapticFeedback.notificationOccurred('success')
        }
        else if (result?.error) {
        tg.HapticFeedback.notificationOccurred('error')
        tg.showPopup({message:result?.error})
    }
    } 

    const updateProperties = async () => {
        const result = await getProperties()
        if (result) setpropterties(result)
    }

    useEffect(() => {
        updateProperties()
    }, [])

  return (
    <PageWrapper>
    <div className="screen screen-sell" style={{background: "linear-gradient(180deg, #4D5088 0%, #17182C 100%)"}}>
        <div className="screen-title">sell property</div>
        <div className="screen-desc">
            Sell your property. Get money and invest <br/>in crypto to be left with nothing again.
        </div>
        <div className="screen-sell-user-info">
            <div className="sell-user-info-item">
                <span>Your total loose:</span>
                <div className="user-info-total-loose"> 
                    <span id="total-loose">$ {formatNumber(user?.balance || 0)}</span>
                </div>
            </div>
            <div className="sell-user-info-img">
            <img src={`./images/avatar-user/${user?.pers}.png`} alt="" />
            </div>
            <div className="sell-user-info-item">
                <span>Money from sale:</span>
                <div className="user-info-total-loose"> 
                    <span id="money-sale">$ {formatNumber(user?.money_from_sale || 0)}</span>
                </div>
            </div>
        </div>
        {propterties?.length === 0 && <div className='screen-desc'>You no longer have property for sale</div>}
        <div className="sell-items">
            {
                propterties && propterties.map(({id,title,image,price,level}) => (
                    <div className="sell-item" key={id} onClick={() => sellPropertyHandle(id)}>
                    <div className="sell-item-title">
                        {title}
                    </div>
                    <div className="sell-item-img">
                        <img src={image} alt=""/>
                    </div>
                    <div className="sell-item-footer">
                        <div className="sell-item-lvl-wrapper">
                        </div>
                        <div className="sell-item-price-wrapper">
                            <span className="sell-item-price">$ {price / 1000} K</span>
                        </div>
                    </div>
                </div>
                ))
            }
            
        </div>
    </div>
    </PageWrapper>
  )
}

export default Property