import tg from "utils/tg";
import FriendsInfo from "types/friends-info";
import Property from "types/property";
const tg_id = tg.initDataUnsafe.user?.id;

type Responce = {result?:true,error?:'string'}

export default async (product_id:string | number):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "sell_property";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("product_id", String(product_id));
    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
