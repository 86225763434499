import getFriends from 'api/get-friends'
import PageWrapper from 'components/UI/PageWrapper'
import React, { useEffect, useState } from 'react'
import FriendsInfo from 'types/friends-info'

type Props = {}

const Friends = (props: Props) => {
    const [friends, setfriends] = useState<FriendsInfo | null>(null)

    const updateFriends = async () => {
        const result = await getFriends()
        if (result?.result) setfriends(result.data)
    }

    useEffect(() => {
        updateFriends()
    }, [])
  return (
    <PageWrapper>
        <div className="screen screen-friends">
        <div className="screen-title">Invite your friends!</div>
        <div className="section-invite-items">
            <div className="invite-item">
                <div className="invite-item-title">
                    Invite a friend.
                </div>
                <div className="invite-item-img">
                    <img src="./img/invite-friend.png" alt=""/>
                </div>
                <div className="invite-item-reward">
                    + 1 ticket
                </div>
            </div>
            <div className="invite-item">
                <div className="invite-item-title">
                    Invite a friend
                    with Telegram Premium.
                </div>
                <div className="invite-item-img">
                    <img src="./img/invite-friend-prem.png" alt=""/>
                </div>
                <div className="invite-item-reward">
                    + 2 ticket
                </div>
            </div>
        </div>
        <div className="friend-items-container">
            <span className="friend-items-title">Your friends (<span id="friends-count">{friends?.friends}</span>):</span>
            <div className="friends-items">
                {friends && friends.friends_list.map(({name,photo,tickets},i) => (
                    <div className="friend-item" key={i}>
                        <div className="friend-item-wrapper">
                            <div className="icon-gradient">
                                <div className="friend-item-icon">
                                    <img className="friend-item-icon-img" src={photo} alt=""/>
                                </div>
                            </div>
                            <div className="friend-item-name">{name}</div>
                        </div>
                    <div className="friend-item-number">+ {tickets}</div>
                </div>
                ))}
            </div>

        </div>

        <div className="btn-link" onClick={() => window.navigator.clipboard.writeText(process.env.REACT_APP_TG_SHARE_LINK as string)}>
            <div className="btn-link-title">Invitation Link:</div>
            <div className="btn-link-link">Link</div>
            <div className="btn-link-icon"></div>
        </div>
    </div>
    </PageWrapper>
  )
}

export default Friends