import completeTask from "api/complete-task";
import getTasks from "api/get-tasks";
import PageWrapper from "components/UI/PageWrapper";
import { AppContext } from "hooks/use-app-data";
import { useContext, useEffect, useState } from "react";
import TasksInfo from "types/tasks-info";
import tg from "utils/tg";

type Props = {};

const Tasks = (props: Props) => {
  const [tasks, setTasks] = useState<TasksInfo | null>(null);
  const [pendingTasks, setPendingTasks] = useState<string[]>([]);
  const {setUser,user} = useContext(AppContext)

  const updateTasks = async () => {
    const result = await getTasks();
    if (result?.result) setTasks(result.data);
  };

  const clickHandle = async (taskID:string,url:string) => {
    setPendingTasks([...pendingTasks,taskID])
    try {
      tg.openTelegramLink(url)
    } catch (error) {
      tg.openLink(url)
    }
    const HandleTask = async () => {
      setPendingTasks(pendingTasks.filter(id => id !== taskID))
        const result = await completeTask(taskID)
        const err = () => tg.showAlert('There was some kind of error')
        if (result === null) err()
        else if ('error' in result) tg.showAlert(`Error:${result.error}`)
        else if (user) setUser({...user,balance:result.data.balance})
        else err()
        updateTasks()
    } 

    setTimeout(HandleTask,3000)
  }

  useEffect(() => {
    updateTasks();
  }, []);
  return (
    <PageWrapper>
      <div className="screen screen-task">
        <div className="screen-title">Tasks</div>
        <div className="tasks-items">
          {tasks?.tasks.map(({reward,url,title,id,status}) => {
            const isPending = pendingTasks.findIndex(tID => tID === id) !== -1
            return(
            <div key={id} className="task-item" onClick={() => clickHandle(id,url)}>
              <div className="task-item-title">{title}</div>
              <div className="task-item-group">
                <div className="task-item-coin">+ {reward}</div>
                <div className={`task-item-complete ${isPending ? 'check' : (status && 'checked')}`}></div>
              </div>
            </div>
          )})}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Tasks;
